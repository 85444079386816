import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import { createPlants, updatePlants, deletePlants, getAllPlants } from '../api/plants';

export const authSlice = createSlice({
  name: 'plants',
  initialState: {
    loading: false,
    plants: {},
    error: {},
  },
  reducers: {},
  extraReducers: {
    [getAllPlants.pending]: (state) => {
      state.loading = true;
    },
    [getAllPlants.fulfilled]: (state, action) => {
      state.loading = false;
      state.plants = action.payload;
    },
    [getAllPlants.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [createPlants.pending]: (state) => {
      state.loading = true;
    },
    [createPlants.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.plants;
      const newPlantsList = jsonState?.results;
      const modifiedPlantsList = {
        ...jsonState,
        results: Array.isArray(newPlantsList)
          ? [...newPlantsList, action.payload]
          : [action.payload],
      };
      state.plants = modifiedPlantsList;
      state.loading = false;
    },
    
    [createPlants.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updatePlants.pending]: (state) => {
      state.loading = true;
    },
    [updatePlants.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.lants;
      const modifiedPlantsList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };
      state.plants = modifiedPlantsList;
      state.loading = false;
    },
    [updatePlants.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deletePlants.pending]: (state) => {
      state.loading = true;
    },
    [deletePlants.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.plants;
      const modifiedPlantsList = {
        ...jsonState,
        results: jsonState.results?.filter((itm) => itm?.id !== action.payload),
      };
      state.plants = modifiedPlantsList;
      state.loading = false;
    },
    [deletePlants.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default authSlice.reducer;
