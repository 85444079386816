import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, del, post } from './http';
import { activeSnack } from '../store/common';

export const getAllCluster = createAsyncThunk(
  'cluster/list',
  async ({ page, search, limit, dispatch,species }) => {
    try {
      const response = await get(
        `/cluster/admin/cluster/?page=${page ?? 1}&search=${search ?? ''}&page_size=${limit ?? 5}&default_species=${species ?? ''}`
      );
      if (response?._id) {
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createCluster = createAsyncThunk(
  'cluster/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const response = await post(`/cluster/admin/cluster/`, state);
      if (response?.id) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Cluster created successfully' }));
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCluster = createAsyncThunk(
  'cluster/update',
  async ({ state, clusterId, dispatch, handleClose }) => {
    try {
      const response = await put(`/cluster/admin/cluster/${clusterId}/`, state);
      if (response?.id) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Cluster updated successfully' }));
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCluster = createAsyncThunk(
  'cluster/delete',
  async ({ clusterId, dispatch, handleClose }) => {
    try {
      const response = await del(`/cluster/admin/cluster/${clusterId}/`);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(
          activeSnack({
            type: 'success',
            message: response?.message || 'Cluster deleted successfully',
          })
        );
        return clusterId;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
