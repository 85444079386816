// @mui
import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
// import { getDashboardData } from 'src/server/api/dashboard';

import { useSettingsContext } from 'src/components/settings';

// components

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardAdminApp() {
  // const { enqueueSnackbar } = useSnackbar();
  // const dispatch = useDispatch();
  // const [selectedYear, setSelectedYear] = useState('2024');

  // const { dashLoading } = useSelector((state) => ({
  //   dashLoading: state.dashboard.loading,
  // }));

  // useEffect(() => {
  //   const credentials = {
  //     enqueueSnackbar,
  //   };
  //   dispatch(getDashboardData(credentials));
  // }, [dispatch, enqueueSnackbar]);

  // useEffect(() => {
  //   const credentialsYear = {
  //     enqueueSnackbar,
  //     year: selectedYear,
  //   };

  //   dispatch(getPartnerEarningChartData(credentialsYear));
  //   // dispatch(getPartnerSalesChartData(credentialsYear));
  // }, [dispatch, enqueueSnackbar, selectedYear]);

  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Dashboard" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: 4 }}>
        Dashboard
        {/* {!dashLoading ? (
          <DashboardContent setSelectedYear={setSelectedYear} selectedYear={selectedYear} />
        ) : (
          <DashboardLoading />
        )} */}
      </Container>
    </>
  );
}
