import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

export const getAllClusterActivity= createAsyncThunk(
  'clusterActivity/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `/cluster/admin/cluster-activity/?page=${page ?? 1}&search=${search ?? ''}&page_size=${limit ?? 5}`
      );
      if (response?._id) {
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;

    }
  }
);

// export const createClusterActivity = createAsyncThunk(
//   'ClusterActivity/create',
//   async ({ state, dispatch, handleClose }) => {
//     try {
//       const response = await post(`/cluster/admin/cluster-activity/`, state);
//       if (response?.id) {
//         if (handleClose) {
//           handleClose();
//         }
//         dispatch(activeSnack({ type: 'success', message: 'Cluster Activity created successfully' }));
//         return response;
//       }
//       return response;
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

// export const updateClusterActivity = createAsyncThunk(
//   'clusterActivity/update',
//   async ({ state, clusterActivityId, dispatch, handleClose }) => {
//     try {
//       const response = await put(`/cluster/admin/cluster-activity/${clusterActivityId}/`, state);
//       if (response?.id) {
//         if (handleClose) {
//           handleClose();
//         }
//         dispatch(activeSnack({ type: 'success', message: 'Cluster Activity updated successfully' }));
//         return response;
//       }
//       return response;
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

// export const deleteClusterActivity = createAsyncThunk(
//   'ClusterActivity/delete',
//   async ({ clusterActivityId, dispatch, handleClose }) => {
//     try {
//       const response = await del(`/cluster/admin/cluster-activity/${clusterActivityId}/`);
//       if (response) {
//         if (handleClose) {
//           handleClose();
//         }
//         dispatch(
//           activeSnack({
//             type: 'success',
//             message: response?.message || 'Cluster Activity deleted successfully',
//           })
//         );
//         return clusterActivityId;
//       }
//       return response;
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );
