import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import { createCluster, updateCluster, deleteCluster, getAllCluster } from '../api/KandalCluster';

export const authSlice = createSlice({
  name: 'cluster',
  initialState: {
    loading: false,
    cluster: {},
    error: {},
  },
  reducers: {},
  extraReducers: {
    [getAllCluster.pending]: (state) => {
      state.loading = true;
    },
    [getAllCluster.fulfilled]: (state, action) => {
      state.loading = false;
      state.cluster = action.payload;
    },
    [getAllCluster.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [createCluster.pending]: (state) => {
      state.loading = true;
    },
    [createCluster.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.cluster;
      const oldClusterList = jsonState?.results;

      const modifiedClusterList = {
        ...jsonState,

        results: Array.isArray(oldClusterList)
          ? [action.payload, ...oldClusterList]
          : [action.payload],
      };
      state.cluster = modifiedClusterList;
      state.loading = false;
    },
    [createCluster.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateCluster.pending]: (state) => {
      state.loading = true;
    },
    [updateCluster.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.cluster;
      const modifiedClusterList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };
      state.cluster = modifiedClusterList;
      state.loading = false;
    },
    [updateCluster.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },


    [deleteCluster.pending]: (state) => {
      state.loading = true;
    },
    [deleteCluster.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.cluster;
      const modifiedClusterList = {
        ...jsonState,
        results: jsonState.results?.filter((itm) => itm?.id !== action.payload),
      };
      state.cluster = modifiedClusterList;
      state.loading = false;
    },
    [deleteCluster.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});


export default authSlice.reducer;
