import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import authReducer from './auth';
import userReducer from './user';
import stateReducer from './state';
import commonReducer from './common';
import plantsReducer from './plants';
import speciesReducer from './species';
import CountryReducer from './country';
import DistrictReducer from './district';
import settingsReducer from './settings';
import surveyorsReducer from './surveyors';
import dashboardReducer from './dashboard';
import kandalClusterReducer from './KandalCluster';
import clusterActivityReducer from './clusterActivity';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    country: CountryReducer,
    district: DistrictReducer,
    settings: settingsReducer,
    state: stateReducer,
    user: userReducer,
    species: speciesReducer,
    surveyor: surveyorsReducer,
    cluster: kandalClusterReducer,
    plants: plantsReducer,
    clusterActivity: clusterActivityReducer,
  },
});
