import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, del, post } from './http';
import { activeSnack } from '../store/common';

export const getAllsurveyors = createAsyncThunk(
  'surveyors/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `/user/admin/user/?page=${page ?? 1}&search=${search ?? ''}&page_size=${limit ?? 5}`
      );
      if (response?._id) {
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createSurveyor = createAsyncThunk(
  'surveyor/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const response = await post(`/user/admin/user/`, state);
      if (response?.id) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Surveyor created successfully' }));
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSurveyor = createAsyncThunk(
  'surveyor/update',
  async ({ state, surveyorId, dispatch, handleClose }) => {
    try {
      const response = await put(`/user/admin/user/${surveyorId}/`, state);
      if (response?.id) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Surveyor updated successfully' }));
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSurveyor = createAsyncThunk(
  'surveyor/delete',
  async ({ surveyorId, dispatch, handleClose }) => {
    try {
      const response = await del(`/user/admin/user/${surveyorId}/`);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(
          activeSnack({
            type: 'success',
            message: response?.message || 'Surveyor deleted successfully',
          })
        );
        return surveyorId;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
