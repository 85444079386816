import { createSlice } from '@reduxjs/toolkit';

// import { covertToJSON } from 'src/utils/stateToJson';

import {

  getAllClusterActivity,
} from '../api/clusterActivity';

export const authSlice = createSlice({
  name: 'clusterActivity',
  initialState: {
    loading: false,
    clusterActivity: {},
    error: {},
  },
  reducers: {},
  extraReducers: {
    [getAllClusterActivity.pending]: (state) => {
      state.loading = true;
    },
    [getAllClusterActivity.fulfilled]: (state, action) => {
      state.loading = false;
      state.clusterActivity = action.payload;
    },
    [getAllClusterActivity.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // [createClusterActivity.pending]: (state) => {
    //   state.loading = true;
    // },
    // [createClusterActivity.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.clusterActivity;
    //   const newClusterActivityList = jsonState?.results;
    //   const modifiedClusterActivityList = {
    //     ...jsonState,
    //     results: Array.isArray(newClusterActivityList)
    //       ? [...newClusterActivityList, action.payload]
    //       : [action.payload],
    //   };
    //   state.clusterActivity = modifiedClusterActivityList;
    //   state.loading = false;
    // },
    // [createClusterActivity.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },

    // [updateClusterActivity.pending]: (state) => {
    //   state.loading = true;
    // },
    // [updateClusterActivity.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.clusterActivity;
    //   const modifiedClusterActivityList = {
    //     ...jsonState,
    //     results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
    //   };
    //   state.clusterActivity = modifiedClusterActivityList;
    //   state.loading = false;
    // },
    // [updateClusterActivity.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },

    // [deleteClusterActivity.pending]: (state) => {
    //   state.loading = true;
    // },
    // [deleteClusterActivity.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.clusterActivity;
    //   const modifiedClusterActivityList = {
    //     ...jsonState,
    //     results: jsonState.results?.filter((itm) => itm?.id !== action.payload),
    //   };
    //   state.clusterActivity = modifiedClusterActivityList;
    //   state.loading = false;
    // },
    // [deleteClusterActivity.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },
  },
});

export default authSlice.reducer;

