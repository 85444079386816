import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import { createSpecies, updateSpecies, deleteSpecies, getAllSpecies } from '../api/species';

export const authSlice = createSlice({
  name: 'species',
  initialState: {
    loading: false,
    species: {},
    error: {},
  },
  reducers: {},
  extraReducers: {
    [getAllSpecies.pending]: (state) => {
      state.loading = true;
    },
    [getAllSpecies.fulfilled]: (state, action) => {
      state.loading = false;
      state.species = action.payload;
    },
    [getAllSpecies.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [createSpecies.pending]: (state) => {
      state.loading = true;
    },
    [createSpecies.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.species;
      const newSpeciesList = jsonState?.results;
      const modifiedSpeciesList = {
        ...jsonState,
        results: Array.isArray(newSpeciesList)
          ? [...newSpeciesList, action.payload]
          : [action.payload],
      };
      state.species = modifiedSpeciesList;
      state.loading = false;
    },
    [createSpecies.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateSpecies.pending]: (state) => {
      state.loading = true;
    },
    [updateSpecies.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.species;
      const modifiedSpeciesList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };
      state.species = modifiedSpeciesList;
      state.loading = false;
    },
    [updateSpecies.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteSpecies.pending]: (state) => {
      state.loading = true;
    },
    [deleteSpecies.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.species;
      const modifiedSpeciesList = {
        ...jsonState,
        results: jsonState.results?.filter((itm) => itm?.id !== action.payload),
      };
      state.species = modifiedSpeciesList;
      state.loading = false;
    },
    [deleteSpecies.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default authSlice.reducer;
