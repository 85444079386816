import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, del, post } from './http';
import { activeSnack } from '../store/common';

export const getAllPlants= createAsyncThunk(
  'plants/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `/plant/admin/plant/?page=${page ?? 1}&search=${search ?? ''}&page_size=${limit ?? 5}`
      );
      if (response?._id) {
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createPlants = createAsyncThunk(
  'plants/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const response = await post(`/plant/admin/plant/`, state);
      if (response?.id) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'Success', message: 'Plants created successfully' }));
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updatePlants = createAsyncThunk(
  'plants/update',
  async ({ state, plantsId, dispatch, handleClose }) => {
    try {
      const response = await put(`/plant/admin/plant/${plantsId}/`, state);
      if (response?.id) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Plants updated successfully' }));
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deletePlants = createAsyncThunk(
  'plants/delete',
  async ({ plantsId, dispatch, handleClose }) => {
    try {
      const response = await del(`/plant/admin/plant/${plantsId}/`);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(
          activeSnack({
            type: 'success',
            message: response?.message || 'Plants deleted successfully',
          })
        );
        return plantsId;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
); 