import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import { createSurveyor, updateSurveyor, deleteSurveyor, getAllsurveyors } from '../api/surveyors';

export const authSlice = createSlice({
  name: 'surveyor',
  initialState: {
    loading: false,
    surveyors: {},
    error: {},
  },
  reducers: {},
  extraReducers: {
    [getAllsurveyors.pending]: (state) => {
      state.loading = true;
    },
    [getAllsurveyors.fulfilled]: (state, action) => {
      state.loading = false;
      state.surveyors = action.payload;
    },
    [getAllsurveyors.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [createSurveyor.pending]: (state) => {
      state.loading = true;
    },
    [createSurveyor.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.surveyors;
      const newSurveyorList = jsonState?.results;
      const modifiedSurveyorList = {
        ...jsonState,
        results: Array.isArray(newSurveyorList)
          ? [...newSurveyorList, action.payload]
          : [action.payload],
      };
      state.surveyors = modifiedSurveyorList;
      state.loading = false;
    },
    [createSurveyor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateSurveyor.pending]: (state) => {
      state.loading = true;
    },
    [updateSurveyor.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.surveyors;
      const modifiedSurveyorList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };
      state.surveyors = modifiedSurveyorList;
      state.loading = false;
    },
    [updateSurveyor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteSurveyor.pending]: (state) => {
      state.loading = true;
    },
    [deleteSurveyor.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.surveyors;
      const modifiedSurveyorList = {
        ...jsonState,
        results: jsonState.results?.filter((itm) => itm?.id !== action.payload),
      };
      state.surveyors = modifiedSurveyorList;
      state.loading = false;
    },
    [deleteSurveyor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default authSlice.reducer;
